<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .karriere-kontakt-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .person-box {
      position: relative;
      margin-bottom: 50px;

      .txt-box {
        text-align: left;
        padding: 60px 25px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 200%;

          @media(max-width:$width-lg) {
            font-size: 150%;
          }

          @media(max-width:$width-sm) {
            font-size: 130%;
          }
        }

        .job {
          margin-top: 20px;
          margin-bottom: 20px;
          letter-spacing: 1px;
          padding-right: 40px;

          strong {
            display: block;
            font-weight: normal;
            font-family: 'Helvetica Neue LT W05 65 Medium'
          }

          span {
            display: block;
            font-size: 90%;
            line-height: 1.8;
          }
        }

        .zitat {
          font-family: 'American Typewriter ITC W04 Md';

          margin-bottom: 40px;
          font-size: 110%;

          @media(max-width:$width-sm) {
            font-size: 100%;
          }

          .zitat-icon {
            display: block;
          }
        }

      }

      .box-middle {
        border: 1px solid #878787;
      }

      .box-bottom {
        text-align: left;
        border: 1px solid #878787;
        border-top: 0;
        padding: 20px 25px;

        a {
          display: block;
          letter-spacing: 1px;
          font-size: 90%;
          position: relative;

          .icon-img {
            position: absolute;
            right: 0;
            top: 0;

            img {
              width: 30px;
              height: auto;

            }
          }

          &:hover {
            color: #000;
          }
        }
      }
    }



  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="karriere-kontakt-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.ansprechpartnerinnen')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1 v-html="$t('karriere-kontakt.ansprechpartnerinnen-v2')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7">
          <p class="intro-txt text-center padding-t-m padding-b-xl" v-html="$t('karriere-kontakt.intro-txt')"></p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-4 col-md-6">
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/nico-novacek.jpg" alt="Nico Novacek" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Nico Novacek</div>
                <div class="job">
                  <strong>HR-Business-Partner</strong>
                  <span>Die Furche</span>
                  <span>Styria Buchverlage</span>
                  <span>Styria Medienhaus Lifestyle</span>
                  <span>Styria Medienhaus Sport</span>

                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.nico-novacek-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:nico.novacek@styria.com">nico.novacek@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/sanja-jasprica.jpg" alt="Vanessa Bange" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Sanja Jasprica</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Fides Bilanca </span>
                  <span>Styria Digital Development</span>
                  <span>Styria IT Solutions (CRO)</span>
                  <span>Styria medijski servisi</span>
                  <span>Tiskara Zagreb </span>
                  <span>Večernji list </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.sanja-jasprica-txt') }}
                </div>

              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:sanja.jasprica@styria.hr">sanja.jasprica@styria.hr<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/vanessa-bange.jpg" alt="Vanessa Bange" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Vanessa Bange</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>

                  <span>Styria Media Group </span>
                  <span>Styria Media International </span>
                  <span>Styria Facility Management</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.vanessa-bange-txt') }}
                </div>

              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:vanessa.bange@styria.com">vanessa.bange@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/martina-fruerntatt.jpg" alt="Martina Fürntratt" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Martina Fürntratt</div>
                <div class="job"><strong>HR-Business-Partnerin</strong>
                  <span>Styria IT Solutions</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.martina-fuerntratt-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:martina.fuerntratt@styria.com">martina.fuerntratt@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/lisa-maria-kompein.jpg" alt="Lisa-Maria Kompein" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Lisa-Maria Kompein</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Kleine Zeitung</span>
                  <span>MM Produktion und Services </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.lisa-maria-kompein-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:lisa-maria.kompein@styria.com">lisa-maria.kompein@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>



        </div>
        <div class="col-lg-4 col-md-6">
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/kerstin-roth.jpg" alt="Kerstin Roth" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Kerstin Roth</div>
                <div class="job">

                  <strong>HR-Business-Partnerin</strong>
                  <span>Druck Carinthia</span>
                  <span>Druck Styria</span>
                  <span>rca radio content Austria</span>
                  <span>Styria Digital Marketplaces</span>
                  <span>Styria IT Solutions</span>
                  <span>Styria Media Design</span>


                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.kerstin-roth-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:kerstin.roth@styria.com">kerstin.roth@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/anna-korper.jpg" alt="Anna Korper" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Anna Korper</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Call &amp; Mail</span>
                  <span>FIDES</span>
                  <span>Styria Marketing Services</span>

                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.anna-korper-txt') }}
                </div>

              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:anna.korper@styria.com">anna.korper@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>


          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/britta-ebenberger.jpg" alt="Britta Ebenberger" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Britta Ebenberger</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Kleine Zeitung</span>
                  <span>MM Produktion und Services</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.britta-ebenberger-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:britta.ebenberger@styria.com">britta.ebenberger@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/jelena-poljicanin.jpg" alt="Jelena Poljicanin" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Jelena Poljicanin</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>24sata</span>
                  <span>Pixsell</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.jelena-poljicanin-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:jelena.poljicanin@styria.hr">jelena.poljicanin@styria.hr<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/thomas-remes.jpg" alt="Thomas Remes" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Thomas Remes</div>
                <div class="job">
                  <strong>HR-Business-Partner</strong>
                  <span>COPE</span>
                  <span>Die Presse</span>
                  <span>missMEDIA</span>
                  <span>Styria Digital Services</span>
                  <span>Styria Medienhaus Wien</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.thomas-remes-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:thomas.remes@styria.com">thomas.remes@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/michael-gawanda.jpg" alt="Michael Gawanda" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Michael Gawanda</div>
                <div class="job">
                  <strong>{{ $t('karriere-kontakt.geschaeftsfuehrer') }} autoPro24</strong>
                  <span>autoPro24</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.michael-gawanda-txt')
                  }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:bewerbung@autoPro24.at">bewerbung@autoPro24.at<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>


        </div>
        <div class="col-lg-4 col-md-6">


          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/monika-schroll.jpg" alt="Monika Schroll" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Monika Schroll</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Kleine Zeitung</span>
                  <span>MM Produktion und Services </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.monika-schroll-txt') }}
                </div>

              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:monika.schroll@styria.com">monika.schroll@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/deniz-verena-praun-v2.jpg" alt="Deniz-Verena Praun"
              class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Deniz-Verena Praun</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Kleine Zeitung</span>
                  <span>MM Produktion und Services </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.deniz-praun-txt') }}
                </div>

              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:deniz.praun@styria.com">deniz.praun@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/andrea-snopek.jpg" alt="Andrea Snopek" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Andrea Snopek</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>connect724 </span>
                  <span>redmail Logistik & Zustellservice</span>

                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.andrea-snopek-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:andrea.snopek@redmail.at">andrea.snopek@redmail.at<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/mario-renn.jpg" alt="Mario Renn" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Mario Renn</div>
                <div class="job">
                  <strong>HR-Business-Partner</strong>
                  <span>Kleine Zeitung</span>
                  <span>MM Produktion und Services </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.mario-renn-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:mario.renn@styria.com">mario.renn@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/martina-glauninger.jpg" alt="Martina Glauninger" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Martina Glauninger</div>
                <div class="job">
                  <strong>{{ $t('karriere-kontakt.martina-glauninger-job') }}</strong>
                  <span>Antenne Kärnten</span>
                  <span>Antenne Steiermark</span>
                  <span>Radio Flamingo </span>

                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.martina-glauninger-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:martina.glauninger@antenne.at">martina.glauninger@antenne.at<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';



import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Ansprechpartner | Styria Media Group',
      keywords: ['kontakt', 'karriere', 'ansprechpartner', 'hr', 'human resources', 'styria'],
      description: 'Wie gut, zu wissen, wohin man sich wenden kann! Hier gibt es alle Ansprechpartner:innen rund um Jobsuche und Bewerbung auf einen Blick.',
      image: require('@/assets/img/og/karriere.png'),

    },
    en: {
      title: 'contact | Styria Media Group',
      keywords: ['contact', 'career', 'contact person', 'hr', 'human resources', 'styria'],
      description: 'How good it is to know where to turn! Here you can find all contact persons for job search and application at a glance.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,


  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
</script>
